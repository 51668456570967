.faq_container {
    display:flex;
    flex-direction: column;
    min-height:100vh;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem;
}

.faq-h2{
  margin-bottom:2rem;
}

  .faqs {
    width: 94%;
    max-width: 100vw;
    margin: 0 2rem;
    padding: 15px;
    @media screen and (max-width: 915px) {
      width:100%;
    }
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }



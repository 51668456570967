.app__wrapper {
    padding: 0;
  
    .copyright {
      display: none;
    }
  }
  
  .app__header {
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 5rem 2rem 0rem;
  
  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  
  @media screen and (max-width: 915px) {
    flex-direction: column;
    padding: 8rem 1rem 2rem;
  
  }
  
  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
  }
  
  .app__header-info {
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  margin: 0 2rem;
  
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
  }
  
  
  .app__header-badge {
  width: 100%;
  flex:1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  
  .hidedeskop {
    display: block;
  }
  
  @media screen and (min-width: 769px) {
    .hidedeskop {
      display: none;
    }
  }
  
  .cta_social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap:1rem;
    margin-top:0.5rem;
    // @media screen and (min-width: 1000px) {
    //   display: none;
    // }   
    
    .hiddentext {
      display: block; // Default display
      @media (max-width: 914px) {
        display: none; // Hide on smaller screens
      }
    }

    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #313BAC;
      color:white;
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
      
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color:white;
      }
  
      &:hover {
        background-color: var(--white-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--secondary-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  
  .newsletterBtn {
    width: max-content;
    display: inline-block;
    background: var(--secondary-color);
    color: var(--primary-color);
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    cursor: pointer;
    margin-left:0.8rem;
    border: 1px solid var(--secondary-color);
    margin-top:1.5rem;
    font-size:14px;
    transition:0.3s ease-in-out;
    &:hover{
      background: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
  
    }
    @media screen and (max-width: 1015px) {
        margin-top:1rem;
      }
  }
  
  
  .badge-cmp{
    padding: 1.2rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    min-width:37vw;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 915px) {
      padding: 1.3rem 0.8rem;
      }
  
    .p-text {
      padding-left:0;
      font-size: 1rem;
      @media screen and (max-width: 915px) {
      padding-left:1.5rem;
      margin-top:1rem; 
      }
    }
  
    h1.head-text {
      padding-top:0;
      text-align: start;
      text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
      font-size: 3.5rem;
      line-height: 3.5rem;
      padding-bottom:1rem;
      @media screen and (max-width: 915px) {
        font-size: 2rem;
        padding-bottom:0.3rem;
        line-height: 2rem;
      }
    }
    
    .cta_section_info_p{
      font-size:1rem;
    color: var(--secondary-color);
    font-weight: 600;
    padding-bottom:0.6rem;
    color:#696969;
    @media screen and (max-width: 915px) {
      font-size: 1rem;
      padding-bottom:0.3rem;
      line-height: 2rem;
      padding-left:1.5rem;
    }
    }
  }
  
  .tag-cmp {
    padding: 0.4rem 1rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 915px) {
      padding: 1rem 2rem;
      }
  }
  
  .tag-cmp {
    flex-direction: column;
    margin-top: 1.5rem;
  
    .p-text {
      width: 100%;
    
      font-size:1rem;
      margin:0.4rem 0;   
    }
  }
  
  span {
    font-size: 2.5rem;
  
    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
  
  @media screen and (max-width: 915px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
  }
  
  .app__header-circles {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;
  
  @media screen and (max-width: 915px) {
    margin-top: 3rem;
  }

  // Style for Link components
  a {
    text-decoration: none;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
  
  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
    img {
      width: 60%;
      height: 60%;
    }
  }
  
  // circles with different height & width
  a:nth-child(1) div {
    width: 115px;
    height: 115px;
    background-color: white;
  }
  
  a:nth-child(2) div {
    margin: 1.75rem;
    width: 100px;
    height: 100px;
  }
  
  a:nth-child(3) div {
    width: 85px;
    height: 85px;
  }
  
  @media screen and (max-width: 915px) {
    a:nth-child(1) div {
      width: 85px;
      height: 85px;
      margin: 1rem;
      margin-right: 2rem;
    }
  
    a:nth-child(2) div {
      margin: 1.5rem;
      margin-top: 1rem;
      width: 80px;
      height: 80px;
    }
  
    a:nth-child(3) div {
      width: 80px;
      height: 80px;
      margin-top: 1rem;
    }
  }
  
  @media screen and (min-width: 2000px) {
    a:nth-child(1) div {
      width: 230px;
      height: 230px;
    }

    a:nth-child(2) div {
      width: 300px;
      height: 300px;
    }
  
    a:nth-child(3) div {
      width: 170px;
      height: 170px;
    }
  }
  
  @media screen and (max-width: 915px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
  
    div {
      margin: 1rem;
    }
  }
  }
  
  .app__header-img {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  margin-top:0rem;
  
  @media screen and (max-width: 915px) {
    margin: 0;
    margin-bottom:0;
  }
  
  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }
  
  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  
  @media screen and (max-width: 915px) {
    margin: 0;
  }
  }
  
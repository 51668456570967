
.contact_container {
    width:100%;
    padding:1rem 2rem;
    min-height:100vh;
    display:flex;

    flex-direction: column;
    overflow:hidden;
    @media screen and (max-width: 915px) {
        padding: 5rem 1rem 5rem 1rem;
      }


      .hidedeskop {
        display: block;
      }
      
      @media screen and (min-width: 769px) {
        .hidedeskop {
          display: none;
        }
      }
      
      .cta_social {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap:1rem;
        margin-top:1.5rem;
        @media screen and (min-width: 1000px) {
          display: none;
        }   
      
        div {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #313BAC;
          color:white;
          margin: 0.25rem 0;
          border: 1px solid var(--lightGray-color);
          
          display: flex;
          justify-content: center;
          align-items: center;
      
          transition: all 0.3s ease-in-out;
      
          svg {
            width: 15px;
            height: 15px;
            color:white;
          }
      
          &:hover {
            background-color: var(--white-color);
            border-color: var(--secondary-color);
      
            svg {
              color: var(--secondary-color);
            }
          }
      
          @media screen and (min-width: 2000px) {
            width: 70px;
            height: 70px;
      
            margin: 0.5rem 0;
      
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }


.title {
    display:flex;
    justify-content: center;
    font-size:50px;
    margin-bottom:6rem;
    margin-top:1rem;
}

.subtitle {
    display:flex;
    justify-content: center;
    font-size:24px;
    margin-bottom:0;
    color:var(--secondary-color);
}

.contact_Container {

    display:flex;
    flex-direction:row;
    width:100%;
    gap: 4rem;

    @media screen and (max-width: 915px) {
        flex-direction:column;
      }

.contact__options {
    display:flex;
    flex-direction:column;
    gap:1.5rem;
}

.contact__option1 {
    background: white;
    padding:2.5rem 5rem;
    margin: 0;
    border-radius:1.2rem;
    text-align:center;
    border:1px solid black;
    transition: var(--transition);
}

.contract__option1:hover {
    background:transparent;
    border-color: var(--color-primary-variant);
}

.contact__option1-icon {
    font-size:1.5rem;
    margin-bottom:0.5rem;
}

.contact__option1 a {
    margin-top:0.7rem;
    display:inline-block;
    width: 100%;
    font-size:0.8rem;
    text-decoration: none;
    color: darkblue;
}

.contact__option1 h5 {
    color: var(--color-light);
}



/*========= FORM ===========*/

.formContact {
    display:flex;
    flex-direction: column;
    background-color: rgb(243, 243, 243);
    padding: 2rem 1rem 8rem 4rem;
    margin-bottom:1rem;
    border-radius:1.2rem;
    border:1px solid var(--primary-color);
    gap:1.5rem;
    box-shadow: 4px 5px 20px 0px rgba(109, 109, 109, 1);

    @media screen and (max-width: 915px) {
        padding: 2rem 1rem 8rem 1rem;
        width:100%;
       gap:0;
       display:grid;
       grid-template-columns: 1fr;
       gap:3%;
       height: fit-content;
       margin-bottom:1rem;
      }
    
}

.formInput {
    width:100%;
    padding: 1.5rem;
    border-radius:0.5rem;
    background: white;
    border:0.5px solid var(--primary-color);
    resize:none;
    color: black;

}

.formInputMess {
    font-family: "DM Sans", sans-serif;;
    width:100%;
    padding: 1.5rem;
    border-radius:0.5rem;
    background: white;
    border:0.5px solid var(--primary-color);
    resize:none;
    color: black;
    min-height:20vh;
}

.btnForm {
    width: max-content;
    display: inline-block;
    background: var(--primary-color);
    color: var(--secondary-color);
    padding: 0.55rem 1.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--secondary-color);
    margin-bottom:1rem;
    @media screen and (max-width: 1015px) {
        margin-top:1rem;
      }
   
}

.check {
    width:100%;
    display:flex;
    flex-direction: row;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 10px;
    margin: 0.2rem 0;
    @media screen and (max-width: 1015px) {
        margin:1rem;
      }
    
    .checkP{
        margin-left:10px;
        margin-right:20px;
        padding-bottom:3rem;
    }
}


/* ======= MEDIA QUERIES (SMALL DEVICES) ======= */




@media screen and (max-width: 1200px) {
    #contact {
        margin-left:5vw;
        margin-right:5vw;
        margin-bottom:20vh;
    }
}

}
}
footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align:center;
  font-size: 0.9rem;
  margin-top:1rem;
  padding-bottom:2.6rem;
}

::marker {
  display:none;
}

footer a {
  color: var(--color-bg);
  text-decoration: none;
}

.footer__logo {
  font-size:1.5rem;
  font-weight:500;
  margin-bottom:2rem;
  display:inline-block;
  font-family: 'Playfair Display', serif;
}

.permalinks {
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  text-decoration: none;
  gap:2rem;
  margin: 0 auto 2rem;

  ::marker {
    color:transparent;
  }
}

.footer__socials {
  display:flex;
  justify-content:center;
  gap:1rem;
  margin-bottom:2rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius:0.7rem;
  display:flex;
  border:1px solid transparent;
  font-size:1rem;

}

.footer__socials a:hover {
  background:transparent;
  color:var(--color-bg);
  border-color:var(--color-bg);
}

.footer__copyright {
  margin-bottom:4rem;
  color:var(--color-bg);
}




/* ======= MEDIA QUERIES (SMALL DEVICES) ======= */

@media screen and (max-width: 915px) {
 .permalinks{
     flex-direction:column;
     gap:1.5rem;
     text-decoration: none;
 }
}



.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
 
  }

  #about{
    margin-bottom:0;
  }
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    .no-decoration {
      text-decoration: none; /* Removes underline */
      color: inherit; /* Ensures text color matches the parent element */
    }
    
    .no-decoration:hover {
      text-decoration: none; /* Prevents decoration on hover */
    }

  }

  .about-head-text {
    padding-top:5rem;
    font-size:2rem;
    text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
    font-size:2.6rem;

    @media screen and (max-width:1000px) {
        margin-left:1.5rem;
        margin-right:1.5rem;
        font-size:2rem;
    }
  }

  .cta_section_info_h2{
    font-size:2rem;
    text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
    margin-bottom:2rem;
  }
  
  .app__profile-item {
    flex: 0 0 calc(33.33% - 4rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    @media screen and (max-width: 1025px) {
      flex: 0 0 80vw;
    }

  
    img {
      width: 100%;
      height: 200px;
      border-radius: 15px;
      object-fit: cover;
    
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }

.toolscontainer {
    width:100%;
    padding-left:5vw;
    padding-right:5vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    margin-bottom:-40vh;
    margin-top:-20vh;
    @media screen and (max-width: 915px) {
        padding-left:0;
        padding-right:0;
       min-height:100%;
        margin-bottom:-30vh;
      }
      .toolsContainerDesc{
        color:white;
      }
      .toolsContainera{
        text-decoration:none;
        
      }
}

.titleh2 {
    display:flex;
    font-size:50px;
    margin-bottom:1rem;
    color:var(--lightGray-color);
    text-shadow: -1px 0 rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
    text-align: center;
}

.subtitleh5 {
    font-size:24px;
    margin-bottom:-14px;
    color:var(--lightGray-color);
    text-shadow: -1px 0 rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
    @media screen and (max-width: 1015px) {
        margin-bottom:0;
      }
}

.subtitleh6{
    font-size:18px;
    text-align:center;

    color:var(--lightGray-color);
    text-shadow: -1px 0 rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
    @media screen and (max-width: 1015px) {
        margin-bottom:0;
      }
}


.toolsContainer {

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height:75vh;
    width:70vw;
    background-color: rgb(243, 243, 243);
    padding: 1.5rem 2rem;
    border-radius:1.2rem;
    gap:1.5rem;
    box-shadow: 4px 5px 20px 0px rgba(109, 109, 109, 1);
    text-decoration: none;
      
    background: url("../../assets/toolsbg.jpg");
    background-size: fill;
    background-position: center;
    background-repeat: repeat;

    @media screen and (max-width: 1015px) {
        padding: 0 2rem;
        max-width:100vw;
       gap:3%;
       background-size: cover;
       margin-bottom:0;
       height:100vh;
       text-align:center;
      }
}



.toolsBtn {
    width: max-content;
    display: inline-block;
    background: rgb(139, 14, 249);
    color: var(--primary-color);
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 0px solid var(--secondary-color);
    margin-bottom:0;
    font-size:14px;
    transition:0.3s ease-in-out;
    @media screen and (max-width: 1015px) {
        margin-top:1rem;
      }
}

.toolsBtn:hover {
    background: var(--primary-color);
    color: var(--secondary-color);
  }



/* ======= MEDIA QUERIES (SMALL DEVICES) ======= */




@media screen and (max-width: 1200px) {
    #contact {
        margin-left:5vw;
        margin-right:5vw;
        margin-bottom:20vh;
    }
}



.features-tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .features-tab_h2{
        font-size:2rem;
        text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
        margin-bottom:2rem;
      }

      .features-tab_p{
        font-size:1rem;
      font-weight: 600;
      margin:1rem 0;   
      }
  
    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
    }
  
    &__image {
      display: none; // Hide the image on mobile

      @media (min-width: 768px) { // Show the image on larger screens
        flex: 1;
        max-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          border-radius: 12px;
          width: 50%;
          height: auto;
          object-fit: cover;
        }
      }
    }

    &__content {
      flex: 1;
      max-width: 100%; // Allow content to take full width when image is hidden
      padding: 20px;

      &__title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &__desc {
        font-size: 1rem;
        color: #666;
        margin-bottom: 10px;
      }
    }
  }
  
.ctaContainer {
    flex: 1;
    width: 100%;
    min-height: 30vh;
    flex-direction: column;
    padding:0 1rem;
    margin-top: 2rem;
    @media screen and (max-width: 915px) {
      flex-direction: column;
      padding:0 2rem;
      width: 80%;
    }
  
    .heading_mainsection {
          display: flex;
          flex-direction: row;
          min-height: 50vh;
          width: 100%; 
          padding: 0 0;
          @media screen and (max-width: 1000px) {
            flex-direction: column;
            min-height: 30vh;
            padding:0 0;
          }
  
          .heading_section_1{
            flex: 1;
            width: 30%;
            padding: 0.2rem 1rem;
            @media screen and (max-width: 1000px) {
              width: 100%;
              order:2;
              margin-top:5rem;
            }   

            .cta_section_info_p{
                font-size:1rem;
              color: var(--secondary-color);
              font-weight: 600;  
              }
  
              .cta_section_info_h2{
                font-size:2rem;
                text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
                margin-bottom:1rem;
              }
  
              .cta_section_info_basicp{
                color: #696969;
              }
            
          }
          .heading_section_2 {
              flex: 2;
              width: 70%;
              @media screen and (max-width: 1000px) {
                width: 100%;
                order:1;
              }   
  
              .cta_section_info_p{
                font-size:1rem;
              color: var(--secondary-color);
              font-weight: 600;
              margin:1rem 0;   
              }
  
              .cta_section_info_h2{
                font-size:2rem;
                text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
                margin-bottom:2rem;
              }
  
              .cta_section_info_basicp{
                color: #696969;
              }
  
              .cta_social {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                gap:1rem;
                margin-top:1.5rem;
                @media screen and (max-width: 1000px) {
                  flex-direction: row;
                }   
              
                div {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: #313BAC;
                  color:white;
                  margin: 0.25rem 0;
                  border: 1px solid var(--lightGray-color);
                  
                  display: flex;
                  justify-content: center;
                  align-items: center;
              
                  transition: all 0.3s ease-in-out;
              
                  svg {
                    width: 15px;
                    height: 15px;
                    color:white;
                  }
              
                  &:hover {
                    background-color: var(--white-color);
                    border-color: var(--secondary-color);
              
                    svg {
                      color: var(--secondary-color);
                    }
                  }
              
                  @media screen and (min-width: 2000px) {
                    width: 70px;
                    height: 70px;
              
                    margin: 0.5rem 0;
              
                    svg {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
  
              .newsBtn {
                width: max-content;
                display: inline-block;
                background: var(--secondary-color);
                color: var(--primary-color);
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                cursor: pointer;
                margin-top:1rem;
                border: 1px solid var(--secondary-color);
                margin-bottom:0;
                font-size:14px;
                transition:0.3s ease-in-out;
                &:hover{
                  background: var(--primary-color);
                color: var(--secondary-color);
                border: 1px solid var(--secondary-color);
  
                }
                @media screen and (max-width: 1015px) {
                    margin-top:1rem;
                  }
              }
  
          }
        }
  
         .cta_section2 {
          display: flex;
          flex-direction: row;
          min-height: 70vh;
          width: 100%; 
          padding: 3rem 0;
          @media screen and (max-width: 1000px) {
            flex-direction: column;
          }
  
          .cta_section_img{
            flex: 1;
            width: 50%;
            @media screen and (max-width: 1000px) {
              width: 100%;
            }    
            
            img {
                width: 40vw;
                @media screen and (max-width: 1000px) {
                  display:none;
                }  
              }
          }
          .cta_section_info {
              flex: 1;
              width: 50%;
              @media screen and (max-width: 1000px) {
                width: 100%;
              }   
  
              .cta_section_info_p{
                font-size:1rem;
              color: var(--secondary-color);
              font-weight: 600;
              margin:1rem 0;   
  
              }
  
              .cta_section_info_h2{
                font-size:2rem;
                text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
                margin-bottom:2rem;
              }
  
              .cta_section_info_basicp{
                color: #696969;
              }
  
              .consBtn {
                width: max-content;
                display: inline-block;
                background: var(--secondary-color);
                color: var(--primary-color);
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                cursor: pointer;
                border: 1px solid var(--secondary-color);
                margin-top:2rem;
                font-size:14px;
                transition:0.3s ease-in-out;
                &:hover{
                  background: var(--primary-color);
                color: var(--secondary-color);
                border: 1px solid var(--secondary-color);
  
                }
                @media screen and (max-width: 1015px) {
                    margin-top:1rem;
                  }
              }
          }
        }
  
  }
  
  
  
  // .ctaContainer {
  //     flex: 1;
  //     width: 100%;
  //     min-height:100vh;
  //     flex-direction: column; 
  
    
  //     .cta_section {
  //         display:flex;
  //         flex-direction: row;
  //         min-height:70vh;
  //         width:100%;
  
  //     .cta_section_img {
  //       flex:1; 
        
  //       img {
  //       width: 100%;
  //       height: 200px;
  //       border-radius: 15px;
  //       object-fit: cover;
  //     }
  //     }
  
  //     .cta_section_info {
  //       flex:1;
  //     }
  
  
  //     .ctaTitle {
  //         padding-top:2rem;
  //         @media screen and (max-width:1000px) {
  //             font-size:1.6px;
  //         }
  //     }
    
  // }
  // }
.clean {

  min-height: 30vh;
  @media screen and (max-width:1000px) {
    max-height:75vh;
    margin:2rem 0rem 1rem 0rem;
    flex-direction:row;
  }
}

.sliderContainer {
    max-width: 100%;
    min-height: 70vh;
    flex-direction: column;
    margin:5rem 0rem 1rem 0rem;
    overflow:hidden;
    @media screen and (max-width:1000px) {
      max-height:75vh;
      margin:2rem 0rem 1rem 0rem;
      flex-direction:row;
    }

    .heading_mainsection {
      min-height: 20vh;
      max-height: 40vh;
      margin-bottom:0px;
      padding-bottom:0px;
    }
    
        .slider_headers{
            display:flex;
            flex-direction: column;
            text-align:center;
  
              .slider_p{
                font-size:1rem;
              color: var(--secondary-color);
              font-weight: 600;
              margin:1rem 0;   
  
              }
  
              .slider_h2{
                font-size:2rem;
                text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
                margin-bottom:2rem;
                @media screen and (max-width: 920px) {
                  font-size:1.4rem;
                }
              }

              .newsletterBtn {
                width: max-content;
                display: inline-block;
                background: var(--secondary-color);
                color: var(--primary-color);
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                cursor: pointer;
                margin-left:0.8rem;
                border: 1px solid var(--secondary-color);
                margin-top:1.5rem;
                font-size:14px;
                transition:0.3s ease-in-out;
                &:hover{
                  background: var(--primary-color);
                color: var(--secondary-color);
                border: 1px solid var(--secondary-color);
              
                }
                @media screen and (max-width: 1015px) {
                    margin-top:1rem;
                  }
              }

              .consBtn {
                width: max-content;
                display: inline-block;
                background: var(--secondary-color);
                color: var(--primary-color);
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                cursor: pointer;
                border: 1px solid var(--secondary-color);
                margin-top:2rem;
                font-size:14px;
                transition:0.3s ease-in-out;
                &:hover{
                  background: var(--primary-color);
                color: var(--secondary-color);
                border: 1px solid var(--secondary-color);
  
                }
                @media screen and (max-width: 1015px) {
                    margin-top:1rem;
                  }
              }
        }


        .sliderApp{
          min-height:30vh;
          margin-top:-6rem;
          @media screen and (max-width: 1015px) {
            margin-top:1rem;
            min-height:80vh;
            margin-top:0rem;
          
          }
          .swiper {
            width: 100%;
            min-height: 80vh;
            @media screen and (max-width:1000px) {
              min-height: 80vh;
            margin-top:0rem;
          }
          }
          
          .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
          
            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .swiper-slide img {
            display: block;
            width: 100%;
            height:100%;
            object-fit: contain;
            border-radius:10px;
          }
          
          .swiper-slide {
            width: 70%;
          }
          
          .swiper-slide:nth-child(2n) {
            width: 70%;
          }
          
          .swiper-slide:nth-child(3n) {
            width: 80%;
          }
          
        }
  }
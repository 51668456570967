.publContainer{
    width:80vw;
    height:30vh;
    margin-top:-10rem;

    @media screen and (max-width: 915px) {
        margin-top:-18rem;
        margin-bottom:15rem;
    }

    .publ_headers{
        display:flex;
        flex-direction: column;
        text-align:center;

          .publ_p{
            font-size:1rem;
          color: var(--secondary-color);
          font-weight: 600;
          margin:1rem 0;   

          }

          .publ_h2{
            font-size:2rem;
            text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
            margin-bottom:2rem;
          }
        }

    .publ__cards {   
        display: grid;
        width:100%;
        grid-template-columns: repeat(3, 33%);
        padding:0;
        @media screen and (max-width: 1000px) {
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            width:100%;
        }
    
    
    a {
    text-decoration: none;
    color: var(--secondary-color);
    width: 100%;

    .publ__card {
        background: white;
        border: 1px solid transparent;
        border-radius: 1rem;
        width: 90%;
        padding: 15% 5% 10% 5%;
        margin-top: 2rem;
        text-align: center;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
    
        @media screen and (max-width: 915px) {
            border: 1px solid white;
            width: 100%;
        }
    
        &:hover {
            box-shadow: -1px 3px 10px 0px rgba(236, 236, 236, 1);
        }
    
        .publ__icon {
            color: #313BAC;
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
    
        h5 {
            font-size: 1.3rem;
            margin-bottom: 0.5rem;
        }
    
        small {
            color: rgb(40, 40, 40);
            font-size: 1rem;
        }
    }
    }
    
    .publ__content small {
        color: var(--color-light);
        font-size: 1rem;
        color: rgb(40, 40, 40);
    }
    }
    }
.pricing_container {
    width:100%;
    padding:4rem 2rem;
    min-height:100vh;
    display:flex;
    flex-direction: column;
    overflow:hidden;
    @media screen and (max-width: 915px) {
        padding: 3rem 2rem 5rem 2rem;
      }



      .cta_social {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap:1rem;
        margin-bottom:2.5rem;
        margin-top:-0.8rem;
      
        div {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #313BAC;
          color:white;
          margin: 0.25rem 0;
          border: 1px solid var(--lightGray-color);
          
          display: flex;
          justify-content: center;
          align-items: center;
      
          transition: all 0.3s ease-in-out;
      
          svg {
            width: 15px;
            height: 15px;
            color:white;
          }
      
          &:hover {
            background-color: var(--white-color);
            border-color: var(--secondary-color);
      
            svg {
              color: var(--secondary-color);
            }
          }
      
          @media screen and (min-width: 2000px) {
            width: 70px;
            height: 70px;
      
            margin: 0.5rem 0;
      
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }


      
    .title {
        display:flex;
        justify-content: center;
        font-size:4rem;
        margin-bottom:1rem;
        margin-top:1rem;
        @media screen and (max-width: 915px) {
            font-size:2.4rem;
        margin-bottom:1.4rem;
          }
    }

    .subtitle {
        display:flex;
        justify-content: center;
        font-size:24px;
        margin-bottom:0;
        color:var(--secondary-color);
    }

      .subtitleForm {
        display:flex;
        justify-content: center;
        text-align:center;
        font-size:2rem;
        margin:2rem 0 2rem;
      
    }


    .pricing_contact_container{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items:flex-start;
        text-align:center;
        gap:3rem;
        @media screen and (max-width: 915px) {
            flex-direction:column;
          }

      .pricing_contact__options {
        display:flex;
        flex-direction:column;
        justify-content: center;
        width:30%;
        gap:1.5rem;
        margin:0 0 3rem 0;
        @media screen and (max-width: 915px) {
            flex-direction:column;
            width:100%;
          }
        .pricing_contact__option {
        background: white;
        padding:2.5rem 4.5rem;
        margin: 0;
        border-radius:1.2rem;
        text-align:center;
        border:1px solid black;
        transition: var(--transition);
    }

    .pricing_contract__option:hover {
        background:transparent;
        border-color: var(--color-primary-variant);
    }

    .pricing_contact__option-icon {
        font-size:1.5rem;
        margin-bottom:0.5rem;
    }

    .pricing_contact__option a {
        margin-top:0.7rem;
        display:inline-block;
        font-size:0.8rem;
        text-decoration: none;
        color: darkblue;
    }

    .pricing_contact__option h5 {
        color: var(--color-light);
    }
    
        }







/*========= FORM ===========*/

.formContact {
    display:flex;
    flex-direction: column;
    width:60%;
    background-color: rgb(243, 243, 243);
    padding: 3rem 2rem 2rem 2rem;
    margin-right: 2rem;
    border-radius:1.2rem;
    border:1px solid var(--primary-color);
    gap:1.5rem;
    box-shadow: 4px 5px 20px 0px rgba(109, 109, 109, 1);

    @media screen and (max-width: 915px) {
        padding: 2rem 1rem 14rem 1rem;
        width:100%;
       gap:0;
       display:grid;
       margin: 0 0;
       grid-template-columns: 1fr;
       gap:3%;
       height: fit-content;
       margin-bottom:1rem;
      }
      @media screen and (max-width: 768px) {
        padding: 2rem 1rem 12rem 1rem;
      }
    
}

.formInput {
    width:100%;
    padding: 1.5rem;
    border-radius:0.5rem;
    background: white;
    border:0.5px solid var(--primary-color);
    resize:none;
    color: black;

}

.formInputMess {
    font-family: "DM Sans", sans-serif;;
    width:100%;
    padding: 1.5rem;
    border-radius:0.5rem;
    background: white;
    border:0.5px solid var(--primary-color);
    resize:none;
    color: black;
    min-height:20vh;
}

.btnPricingForm {
    width: max-content;
    display: inline-block;
    background: var(--primary-color);
    color: var(--secondary-color);
    padding: 0.55rem 1.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--secondary-color);
    margin-bottom:1rem;
    @media screen and (max-width: 1015px) {
        margin-top:1rem;
      }
   
}

.check {
    width:100%;
    display:flex;
    flex-direction: row;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 10px;
    margin: 0.2rem 0;
    @media screen and (max-width: 1015px) {
        margin:0;
      }
      @media screen and (max-width: 768px) {
        margin:0;
      }
    
    .checkP{
        margin-left:10px;
        margin-right:20px;
        padding-bottom:0;
    }
}


/* ======= MEDIA QUERIES (SMALL DEVICES) ======= */




@media screen and (max-width: 1200px) {
    #contact {
        margin-left:5vw;
        margin-right:5vw;
        margin-bottom:20vh;
    }
}

}
}


















// .pricing_container {
//     width:100%;
//     padding:4rem 2rem;
//     min-height:100vh;
//     display:flex;
//     flex-direction: column;
//     overflow:hidden;
//     @media screen and (max-width: 915px) {
//         padding: 3rem 2rem 5rem 2rem;
//       }

//       .subtitleForm {
//         display:flex;
//         justify-content: center;
//         text-align:center;
//         font-size:2rem;
//         margin:2rem 0 2rem;
      
//     }


//       .pricing_contact__options {
//         display:flex;
//         flex-direction:row;
//         justify-content: center;
//         gap:1.5rem;
//         margin:0 0 3rem 0;
//         @media screen and (max-width: 915px) {
//             flex-direction:column;
//           }
//     }


// .title {
//     display:flex;
//     justify-content: center;
//     font-size:50px;
//     margin-bottom:6rem;
//     margin-top:1rem;
// }

// .subtitle {
//     display:flex;
//     justify-content: center;
//     font-size:24px;
//     margin-bottom:0;
//     color:var(--secondary-color);
// }



// .contact_options_Container {
//     display:flex;
//     flex-direction: row;
//     justify-content: center;
//     gap:4rem;
//     margin:0 0 5rem 0;
// }


// .pricing_contact__option {
//     background: white;
//     padding:2.5rem 4.5rem;
//     margin: 0;
//     border-radius:1.2rem;
//     text-align:center;
//     border:1px solid black;
//     transition: var(--transition);
// }

// .pricing_contract__option:hover {
//     background:transparent;
//     border-color: var(--color-primary-variant);
// }

// .pricing_contact__option-icon {
//     font-size:1.5rem;
//     margin-bottom:0.5rem;
// }

// .pricing_contact__option a {
//     margin-top:0.7rem;
//     display:inline-block;
//     font-size:0.8rem;
//     text-decoration: none;
//     color: darkblue;
// }

// .pricing_contact__option h5 {
//     color: var(--color-light);
// }


// /*========= FORM ===========*/

// .formContact {
//     display:flex;
//     flex-direction: column;
//     background-color: rgb(243, 243, 243);
//     padding: 3rem 2rem 2rem 2rem;
//     margin: 1rem 11rem;
//     border-radius:1.2rem;
//     border:1px solid var(--primary-color);
//     gap:1.5rem;
//     box-shadow: 4px 5px 20px 0px rgba(109, 109, 109, 1);

//     @media screen and (max-width: 915px) {
//         padding: 2rem 1rem 14rem 1rem;
//         width:100%;
//        gap:0;
//        display:grid;
//        margin: 0 0;
//        grid-template-columns: 1fr;
//        gap:3%;
//        height: fit-content;
//        margin-bottom:1rem;
//       }
//       @media screen and (max-width: 768px) {
//         padding: 2rem 1rem 12rem 1rem;
//       }
    
// }

// .formInput {
//     width:100%;
//     padding: 1.5rem;
//     border-radius:0.5rem;
//     background: white;
//     border:0.5px solid var(--primary-color);
//     resize:none;
//     color: black;

// }

// .formInputMess {
//     font-family: "DM Sans", sans-serif;;
//     width:100%;
//     padding: 1.5rem;
//     border-radius:0.5rem;
//     background: white;
//     border:0.5px solid var(--primary-color);
//     resize:none;
//     color: black;
//     min-height:20vh;
// }

// .btnPricingForm {
//     width: max-content;
//     display: inline-block;
//     background: var(--primary-color);
//     color: var(--secondary-color);
//     padding: 0.55rem 1.5rem;
//     border-radius: 0.4rem;
//     cursor: pointer;
//     border: 1px solid var(--secondary-color);
//     margin-bottom:1rem;
//     @media screen and (max-width: 1015px) {
//         margin-top:1rem;
//       }
   
// }

// .check {
//     width:100%;
//     display:flex;
//     flex-direction: row;
//     text-align: start;
//     align-items: flex-start;
//     justify-content: flex-start;
//     font-size: 10px;
//     margin: 0.2rem 0;
//     @media screen and (max-width: 1015px) {
//         margin:0;
//       }
//       @media screen and (max-width: 768px) {
//         margin:0;
//       }
    
//     .checkP{
//         margin-left:10px;
//         margin-right:20px;
//         padding-bottom:0;
//     }
// }


// /* ======= MEDIA QUERIES (SMALL DEVICES) ======= */




// @media screen and (max-width: 1200px) {
//     #contact {
//         margin-left:5vw;
//         margin-right:5vw;
//         margin-bottom:20vh;
//     }
// }


// }
.app__agenttabs-container {
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) { 
      display: none; 
  }


    .genai-header{
      font-size:2rem;
      text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
      margin-bottom:2rem;
    }

 

  .app__documentation-container {
    display: flex;
    align-items: flex-start;
    padding:0;
    margin:0;
  }
  
  .app__tabs-sidebar {
    width: 200px;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    padding: 10px;
    border-right: 1px solid #ddd;

    

  }


  .Btn {
        width: max-content;
        display: inline-block;
        background: var(--secondary-color);
        color: var(--primary-color);
        padding: 0.8rem 1.6rem;
        border-radius: 0.8rem;
        cursor: pointer;
        border: 1px solid var(--secondary-color);
        margin-top:2rem;
        font-size:14px;
        transition:0.3s ease-in-out;
        &:hover{
          background: var(--primary-color);
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);

        }
        @media screen and (max-width: 1015px) {
            margin-top:1rem;
          }
      }

  .app__tab-button {
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
    
    &.active {
      color: #0073e6;
      font-weight: bold;
    }
  
    &:hover {
      color: #005bb5;
    }
  }
  
  .app__tab-content {
    padding: 20px;
    flex-grow: 1;
  }
  
  
  .app__agenttabs-container {
    width: 80%;
    margin-top: 3rem;
  
    display: flex;
    flex-direction: column;
  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }
  
  .app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top:1rem;
    
  
    @media screen and (max-width: 900px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
  }
  
  .app__skills-item {
    flex-direction: column;
    text-align: center;
  
    margin: 1rem;
  
    transition: all 0.3s ease-in-out;
  
    div {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #fef4f5;
  
      img {
        width: 50%;
        height: 50%;
      }
  
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
  
      @media screen and (max-width: 450px) {
        width: 70px;
        height: 70px;
      }
    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }
  
  .app__skills-exp {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }
  }
  
  .app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
  }
  
  .app__skills-exp-works {
    flex: 1;
  
    .app__skills-exp-work {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;
      cursor: pointer;
  
      h4 {
        font-weight: 500;
      }
  
      p {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
      }
    }
  }
  
  .app__skills-exp-year {
    margin-right: 3rem;
  
    p {
      font-weight: 800;
      color: var(--secondary-color);
    }
  
    @media screen and (max-width: 450px) {
      margin-right: 1rem;
    }
  }
  
  .skills-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }
  
  .app__documentation-container {
    display: flex;
    width: 100%;
    min-height: 60vh;
    margin-top: 2rem;
    gap: 2rem;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .app__tabs-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background: var(--white-color);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
    @media screen and (max-width: 768px) {
      flex-direction: row;
      overflow-x: auto;
      min-width: unset;
    }
  }
  
  .app__tab-button {
    padding: 1rem;
    margin: 0.5rem 0;
    background: none;
    border: none;
    text-align: left;
    font-size: 1rem;
    color: var(--gray-color);
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
  
    &:hover {
      background: var(--secondary-color);
      color: var(--white-color);
    }
  
    &.active {
      background: var(--secondary-color);
      color: var(--white-color);
      font-weight: 500;
    }
  
    @media screen and (max-width: 768px) {
      margin: 0 0.5rem;
      white-space: nowrap;
    }
  }
  
  .app__tab-content {
    flex: 1;
    background: var(--white-color);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
  }

}
.app__animated-about {
  width: 100%;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    padding: 3rem 0.3rem;
  }

  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 3rem;
    margin-bottom: 4rem;
    position: relative;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .grid-item {
      position: relative;
      overflow: hidden;
      border-radius: 15px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-1%) scale(1.01);
      }

      .image-hover-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        
        .image-hover-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s ease-in-out; // Smooth transition for overlay
          border-radius: 15px;
          
          .image-hover-text {
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            transform: translateY(20px); // Initial text position
            opacity: 0;
            transition: all 0.4s ease-in-out; // Smooth transition for text
          }
        }
        
        &:hover .image-hover-overlay {
          opacity: 1;
          visibility: visible;

          .image-hover-text {
            transform: translateY(0);
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
          border-radius: 15px;
          transform: scale(0.97);
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &.large {
        grid-column: span 2;
        grid-row: span 2;
      }

      &.medium {
        grid-column: span 2;
        grid-row: span 1;
      }

      &.small {
        grid-column: span 1;
        grid-row: span 1;
      }

      &:nth-child(1) {
        grid-column: span 2;
        @media screen and (max-width: 768px) {
         display:none;
        }
      }

      &:nth-child(2) {
        grid-column: span 2;
        transform: translateY(-10%) !important;
        @media screen and (max-width: 768px) {
          transform: translateY(0%) !important;
        }
      }

      &:nth-child(3) {
        grid-column: span 2;
        margin-top:-20px;
        transform: translateY(30%) !important;
        @media screen and (max-width: 768px) {
          transform: translateY(0%) !important;
          margin-top:0px;
        }
      }

      .imghead {
        margin-top:1rem;
      }

      &:nth-child(4) {
        grid-column: span 3;
        margin-top:-40px;
        @media screen and (max-width: 768px) {
          transform: translateY(0%) !important;
          margin-top:-20px;
        }
      }

      &:nth-child(5) {
        grid-column: span 2;
        transform: translateY(-5%) !important;
        @media screen and (max-width: 768px) {
          transform: translateY(0%) !important;
          display:none;
        }
      }

      &:nth-child(6) {
        grid-column: span 2;
        transform: translateY(10%) !important;
        @media screen and (max-width: 768px) {
          transform: translateY(0%) !important;
        }
      }

      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }

  .gridcta_section_info {
    flex: 1;
    width: 100%;
    padding: 2rem 4rem;
    background: rgba(255, 248, 250, 0.45);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top:-3rem;
    @media screen and (max-width: 1000px) {
      padding: 2rem 1rem;
      width: 100%;
      order: 1;
      margin-top: 2rem;
    }

    .gridcta_section_info_p {
      font-size: 1rem;
      color: var(--secondary-color);
      font-weight: 600;
      margin: 1rem 0;
    }

    .gridcta_section_info_h2 {
      font-size: 2rem;
      text-shadow: -1px 0 rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
      margin-bottom: 2rem;
    }

    .gridcta_section_info_basicp {
      color: #696969;
    }

    .gridcta_social {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                gap:1rem;
                margin-top:0.5rem;
                @media screen and (max-width: 1000px) {
                  flex-direction: row;
                }
                div {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: #313BAC;
                  color:white;
                  margin: 0.25rem 0;
                  border: 1px solid var(--lightGray-color);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all 0.3s ease-in-out;
                  svg {
                    width: 15px;
                    height: 15px;
                    color:white;
                  }
                  &:hover {
                    background-color: var(--white-color);
                    border-color: var(--secondary-color);
                    svg {
                      color: var(--secondary-color);
                    }
                  }
                  @media screen and (min-width: 2000px) {
                    width: 70px;
                    height: 70px;
                    margin: 0.5rem 0;
                    svg {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }

    .newsBtn {
      width: max-content;
      display: inline-block;
      background: var(--secondary-color);
      color: var(--primary-color);
      padding: 0.8rem 1.6rem;
      border-radius: 0.8rem;
      cursor: pointer;
      margin-top: 1rem;
      border: 1px solid var(--secondary-color);
      margin-bottom: 0;
      font-size: 14px;
      transition: 0.3s ease-in-out;

      &:hover {
        background: var(--primary-color);
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
      }

      @media screen and (max-width: 1015px) {
        margin-top: 1rem;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}




// .app__animated-about {
//   width: 100%;
//   padding: 4rem 2rem;
//   position: relative;
//   overflow: hidden;
//   background-color: transparent;
//   .image-grid-container {
//     display: grid;
//     grid-template-columns: repeat(6, 1fr);
//     gap: 3rem;
//     margin-bottom: 4rem;
//     position: relative;
//     @media screen and (max-width: 768px) {
//       grid-template-columns: repeat(3, 1fr);
//       gap: 1rem;
//     }
//     @media screen and (max-width: 480px) {
//       grid-template-columns: 1fr;
//       gap: 1rem;
//     }
//     .grid-item {
//       position: relative;
//       overflow: hidden;
//       border-radius: 15px;
//       transition: all 0.3s ease-in-out;
//       &:hover {
//         transform: translateY(-1%) scale(1.01);
//       }
//       .gridcta_section_info {
//         flex: 1;
//         width:100%;
//         @media screen and (max-width: 1000px) {
//           width: 100%;
//           order:1;
//         }
//         .gridcta_section_info_p {
//           font-size:1rem;
//           color: var(--secondary-color);
//           font-weight: 600;
//           margin:1rem 0;
//         }
//         .gridcta_section_info_h2 {
//           font-size:2rem;
//           text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
//           margin-bottom:1rem;
//         }
//         .gridcta_section_info_basicp {
//           color: #696969;
//         }
//         .gridcta_social {
//           display: flex;
//           justify-content: flex-start;
//           align-items: center;
//           flex-direction: row;
//           gap:1rem;
//           margin-top:0.5rem;
//           @media screen and (max-width: 1000px) {
//             flex-direction: row;
//           }
//           div {
//             width: 40px;
//             height: 40px;
//             border-radius: 50%;
//             background-color: #313BAC;
//             color:white;
//             margin: 0.25rem 0;
//             border: 1px solid var(--lightGray-color);
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             transition: all 0.3s ease-in-out;
//             svg {
//               width: 15px;
//               height: 15px;
//               color:white;
//             }
//             &:hover {
//               background-color: var(--white-color);
//               border-color: var(--secondary-color);
//               svg {
//                 color: var(--secondary-color);
//               }
//             }
//             @media screen and (min-width: 2000px) {
//               width: 70px;
//               height: 70px;
//               margin: 0.5rem 0;
//               svg {
//                 width: 30px;
//                 height: 30px;
//               }
//             }
//           }
//         }
//         .newsBtn {
//           width: max-content;
//           display: inline-block;
//           background: var(--secondary-color);
//           color: var(--primary-color);
//           padding: 0.8rem 1.6rem;
//           border-radius: 0.8rem;
//           cursor: pointer;
//           margin-top:1rem;
//           border: 1px solid var(--secondary-color);
//           margin-bottom:0;
//           font-size:14px;
//           transition:0.3s ease-in-out;
//           &:hover {
//             background: var(--primary-color);
//             color: var(--secondary-color);
//             border: 1px solid var(--secondary-color);
//           }
//           @media screen and (max-width: 1015px) {
//             margin-top:1rem;
//           }
//         }
//       }
//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         transition: transform 0.3s ease;
//         border-radius: 15px;
//         &:hover {
//           transform: scale(1.1);
//         }
//       }
//       &.large {
//         grid-column: span 2;
//         grid-row: span 2;
//       }
//       &.medium {
//         grid-column: span 2;
//         grid-row: span 1;
//       }
//       &.small {
//         grid-column: span 1;
//         grid-row: span 1;
//       }
//       &:nth-child(1) {
//         grid-column: span 2;
//       }
//       &:nth-child(2) {
//         grid-column: span 2;
//         transform:translateY(-10%) !important;
//       }
//       &:nth-child(3) {
//         grid-column: span 2;
//         transform:translateY(20%) !important;
//       }
//       &:nth-child(4) {
//         grid-column: span 3;
//       }
//       &:nth-child(5) {
//         grid-column: span 3;
//         transform:translateY(-5%) !important;
//       }
//       &:nth-child(6) {
//         grid-column: span 3;
//         transform:translateY(10%) !important;
//       }
//       &:nth-child(7) {
//         grid-column: span 2;
//       }
//     }
//   }
//   .gridcta_section_info {
//     flex: 1;
//     width: 100%;
//     padding: 2rem 4rem;
//     background: rgba(255, 248, 250, 0.45);
//     border-radius: 16px;
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
//     backdrop-filter: blur(5px);
//     -webkit-backdrop-filter: blur(5px);
//     border: 1px solid rgba(255, 255, 255, 0.3);
//     @media screen and (max-width: 1000px) {
//       padding: 2rem 1rem;
//       width: 100%;
//       order:1;
//       margin-top:2rem;
//     }
//     .gridcta_section_info_p {
//       font-size:1rem;
//       color: var(--secondary-color);
//       font-weight: 600;
//       margin:1rem 0;
//     }
//     .gridcta_section_info_h2 {
//       font-size:2rem;
//       text-shadow: -1px 0  rgb(0, 128, 233), 0 1px rgb(148, 0, 116), 1px 0 rgb(106, 0, 0), 0 -1px rgb(163, 163, 163);
//       margin-bottom:2rem;
//     }
//     .gridcta_section_info_basicp {
//       color: #696969;
//     }
//     .cta_social {
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//       flex-direction: row;
//       gap:1rem;
//       margin-top:1.5rem;
//       @media screen and (max-width: 1000px) {
//         flex-direction: row;
//       }
//       div {
//         width: 40px;
//         height: 40px;
//         border-radius: 50%;
//         background-color: #313BAC;
//         color:white;
//         margin: 0.25rem 0;
//         border: 1px solid var(--lightGray-color);
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         transition: all 0.3s ease-in-out;
//         svg {
//           width: 15px;
//           height: 15px;
//           color:white;
//         }
//         &:hover {
//           background-color: var(--white-color);
//           border-color: var(--secondary-color);
//           svg {
//             color: var(--secondary-color);
//           }
//         }
//         @media screen and (min-width: 2000px) {
//           width: 70px;
//           height: 70px;
//           margin: 0.5rem 0;
//           svg {
//             width: 30px;
//             height: 30px;
//           }
//         }
//       }
//     }
//     .newsBtn {
//       width: max-content;
//       display: inline-block;
//       background: var(--secondary-color);
//       color: var(--primary-color);
//       padding: 0.8rem 1.6rem;
//       border-radius: 0.8rem;
//       cursor: pointer;
//       margin-top:1rem;
//       border: 1px solid var(--secondary-color);
//       margin-bottom:0;
//       font-size:14px;
//       transition:0.3s ease-in-out;
//       &:hover {
//         background: var(--primary-color);
//         color: var(--secondary-color);
//         border: 1px solid var(--secondary-color);
//       }
//       @media screen and (max-width: 1015px) {
//         margin-top:1rem;
//       }
//     }
//   }
//   @keyframes fadeIn {
//     0% {
//       opacity: 0;
//       transform: translateY(20px);
//     }
//     100% {
//       opacity: 1;
//       transform: translateY(0);
//     }
//   }
// }

.cta_social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap:1rem;
    margin-top:1.5rem;
    margin-left:1rem;
    @media screen and (max-width: 1000px) {
      flex-direction: row;
    }   
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #313BAC;
      color:white;
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
      
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color:white;
      }
  
      &:hover {
        background-color: var(--white-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--secondary-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

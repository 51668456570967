.app__navbar {
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1.3rem;
    padding: 1rem 1rem 1rem 2rem;
    background: rgba(234, 234, 234, 0.25);
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;
  }
  
  .app__navbar-logo {
    display: flex;
    flex:1.5;
    justify-content: flex-start;
    padding-left:3rem;
    align-items: center;

    @media screen and (max-width: 900px) {
      padding-left:0;
    }
  
  
    img {
      width: 35%;
      height: 35%;

      @media screen and (max-width: 900px) {
        width: 50%;
        height: 50%;
        
      }
      @media screen and (min-width: 2000px) {
        width: 220px;
        height: 60px;

        
      }
    }
  }
  
  .app__navbar-links {
    flex:3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;

    li {
      margin: 0 0.8rem;
      cursor: pointer;
      position: relative; // To anchor the submenu
  
      a {
        color: var(--gray-color);
        text-decoration: none;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  
    // Style for the Portfolio menu and its submenu
    .portfolio-menu {
      &:hover .portfolio-submenu {
        display: block;
      }
  
      .portfolio-submenu {
        display: none;
        position: absolute;
        top: 100%; // Positions below the main Portfolio item
        left: 0;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
  
        li {
          margin: 0;
          white-space: nowrap;
          padding: 0.5rem 1rem;
  
          a {
            color: var(--gray-color);
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
    }

    .button {
      background-color: var(--secondary-color);
      border: 0px solid transparent;
      border-radius: 12px;
      box-sizing: border-box;
      color: white;
      cursor: pointer;
      flex: 0 0 auto;
      font-weight: 600;
      padding: .45rem 1.2rem;
      text-align: center;
      transition-duration: .2s;
     
      a {
        color:white;  
       &:hover {
          color: yellow;
      }
      }
    }
  
    li {
      margin: 0 0.8rem;
      cursor: pointer;
  
      flex-direction: column;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
  
        margin-bottom: 5px;
      }
  
      a {
        color: var(--gray-color);
        text-decoration: none;
        flex-direction: column;
  
        text-transform: uppercase;
        font-weight: 500;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: var(--secondary-color);
        }
      }
  
      &:hover {
        div {
          background: var(--primary-color);
        }
      }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
  
  
    svg {
      width: 35px;
      height: 70%;
      color: var(--white-color);
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      
      background: url('../../assets/bgWhite.png');
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;


    }
  }